import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  BodyText,
  HeadlineL,
  IconButton,
  IconTwitter,
  Logo,
  media,
  tokens,
  useIsViewport,
} from '@pr/components';

const Page = styled.div`
  background: ${tokens.color.neutral.black};
  width: 100vw;
  overflow-y: auto;
  height: -webkit-fill-available;
  height: 100vh;
  max-height: -webkit-fill-available;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  height: 1.875rem;
  margin: 1.5rem 0 0.625rem;
  width: auto;

  ${media.greaterThan('medium')`
    align-self: flex-start;
    margin: 1rem;
  `};
`;

const Image = styled.img`
  width: 100%;
`;

const TextContainer = styled.main`
  position: absolute;
  bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 1rem;

  ${media.greaterThan('medium')`
    bottom: 5.5rem;
  `};
`;

const Heading = styled(HeadlineL)`
  color: ${tokens.color.neutral.white};
  ${tokens.dropshadow.text.onPhoto}
  letter-spacing: 0.01rem;

  ${media.greaterThan('medium')`
    font-size: 4rem;
    line-height: 4.25rem;
  `};
`;

const TextContent = styled(BodyText)`
  margin: 1rem 0;
  text-align: center;

  ${media.greaterThan('medium')`
    margin: 0.75rem 0 2.25rem;
    width: 41.25rem;
  `};
`;

const EmojiIcon = styled.span`
  margin-left: 0.5rem;
`;

const SocialLinks = styled.div`
  margin-top: 0.5rem;
`;

const hostedImagesLink =
  'https://www.romeo.com/wp-content/uploads/offline-page/server-room-';
const romeoXcomLink = 'https://x.com/planetromeo';

/**
 * Shown when the backend services are unreachable (5XX codes) on a login
 * or session GET call.
 *
 * When user clicks try again, the modal closes to give them a chance to try logging in again.
 * */
const OfflinePage = () => {
  const { t } = useTranslation();
  const isMobile = useIsViewport('< medium');
  const isRomeoSite = process.env.REACT_APP_SITE === 'ROMEO';

  const romeoImageSrc = `${hostedImagesLink}${isMobile ? 'a-thumbnail' : 'a'}.jpg`;
  const hunqzImageSrc = `${hostedImagesLink}${isMobile ? 'b-thumbnail' : 'b'}.jpg`;

  const imageAltText = t(
    isRomeoSite
      ? 'offline_page_image_alt_text_romeo'
      : 'offline_page_image_alt_text_hunqz'
  );

  return (
    <Page>
      <StyledLogo />
      <Image
        src={isRomeoSite ? romeoImageSrc : hunqzImageSrc}
        alt={imageAltText}
      />
      <TextContainer>
        <Heading>{t('offline_page_headline')}</Heading>
        <TextContent strong intensity="high">
          {t('offline_page_description')}
          <EmojiIcon>🛠️</EmojiIcon>
        </TextContent>
        {isRomeoSite && (
          <>
            <BodyText strong intensity="high">
              {t('offline_page_check_for_updates')}
            </BodyText>
            <SocialLinks>
              <IconButton
                as="a"
                icon={IconTwitter}
                title="X.com"
                href={romeoXcomLink}
              />
            </SocialLinks>
          </>
        )}
      </TextContainer>
    </Page>
  );
};

export default OfflinePage;
